<template>
  <div>
    <div class="table-operator" style="margin-bottom: 15px">
      <a-button type="primary" icon="plus" @click="modalAddEditLoad()">添加</a-button>
      <a-button type="primary" icon="download" @click="downloadExcel()" style="float: right">下载提货表</a-button>
    </div>
    <s-table
      ref="pageTable"
      :rowKey="(record) => record.id"
      :columns="columns"
      :data="dataset"
      :pagination="pagination"
      :pageSize="10"
    >
      <a slot="name" slot-scope="text">{{ text }}</a>
      <span slot="operation" slot-scope="text, record">
        <a @click="modalAddEditLoad(record)">编辑</a> |
        <a-popconfirm
          title="您确认要删除此条记录吗？"
          ok-text="确认"
          cancel-text="取消"
          @confirm="handleRemove(record)"
        >
          <a>删除</a>
        </a-popconfirm>
      </span>
    </s-table>

    <a-modal
      key="addEdit"
      title="新增/编辑"
      :width="500"
      :footer="null"
      :centered="true"
      :keyboard="false"
      :maskClosable="false"
      :visible="modalAddEditVisible"
      @cancel="modalAddEditCancel"
    >
      <a-form :form="modalAddEditForm" @submit="modalAddEditSubmit">
        <a-form-item
          label="城市区域"
          :labelCol="{ lg: { span: 7 }, sm: { span: 6 } }"
          :wrapperCol="{ lg: { span: 16 }, sm: { span: 12 } }"
        >
          <a-cascader
            name="geoCode"
            placeholder="请选择城市区域"
            v-decorator="['geoCode', modalAddEditFormRules.geoCode]"
            :options="geoCascaderList"
          />
        </a-form-item>

        <a-form-item
          label="首重KG"
          :labelCol="{ lg: { span: 7 }, sm: { span: 6 } }"
          :wrapperCol="{ lg: { span: 16 }, sm: { span: 12 } }"
        >
          <a-input-number
            name="firstWeight"
            :min="0"
            :step="0.01"
            v-decorator="['firstWeight', modalAddEditFormRules.firstWeight]"
          />
          <a-button disabled class="percentage"> KG</a-button>
        </a-form-item>

        <a-form-item
          label="首重价格"
          :labelCol="{ lg: { span: 7 }, sm: { span: 6 } }"
          :wrapperCol="{ lg: { span: 16 }, sm: { span: 12 } }"
        >
          <a-input-number
            name="firstWeightPrice"
            :min="0"
            :step="0.01"
            v-decorator="['firstWeightPrice', modalAddEditFormRules.firstWeightPrice]"
          />
          <a-button disabled class="percentage"> 元</a-button>
        </a-form-item>

        <a-form-item
          label="续重价格"
          :labelCol="{ lg: { span: 7 }, sm: { span: 6 } }"
          :wrapperCol="{ lg: { span: 16 }, sm: { span: 12 } }"
        >
          <a-input-number
            name="continuedWeightPrice"
            :min="0"
            :step="0.01"
            v-decorator="['continuedWeightPrice', modalAddEditFormRules.continuedWeightPrice]"
          />
          <a-button disabled class="percentage"> 元</a-button>
        </a-form-item>

        <a-form-item
          label="封顶价格"
          :labelCol="{ lg: { span: 7 }, sm: { span: 6 } }"
          :wrapperCol="{ lg: { span: 16 }, sm: { span: 12 } }"
        >
          <a-input-number
            name="upperLimitPrice"
            :min="0"
            :step="0.01"
            v-decorator="['upperLimitPrice', modalAddEditFormRules.upperLimitPrice]"
          />
          <a-button disabled class="percentage"> 元</a-button>
        </a-form-item>
        <a-form-item class="form-submit">
          <a-input name="id" type="hidden" style="display: none" v-decorator="['id', modalAddEditFormRules.id]" />
          <a-button style="margin-right: 15px" @click="modalAddEditCancel">取消</a-button>
          <a-button type="primary" htmlType="submit">提交</a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { STable } from '@/components'
import { pickupList, pickupInsert, pickupUpdate, pickupRemove, geoCascaderList } from '@/api/quotation'
import XLSX from 'xlsx'

const columns = [
  {
    key: 'cityName',
    dataIndex: 'cityName',
    title: '城市',
    align: 'center',
  },
  {
    key: 'districtName',
    dataIndex: 'districtName',
    title: '区域',
    align: 'center',
  },
  {
    key: 'firstWeight',
    dataIndex: 'firstWeight',
    title: '首重KG',
    align: 'center',
  },
  {
    key: 'firstWeightPrice',
    dataIndex: 'firstWeightPrice',
    title: '首重价格（元）',
    align: 'center',
  },
  {
    key: 'continuedWeightPrice',
    dataIndex: 'continuedWeightPrice',
    title: '续重价格（元）',
    align: 'center',
  },
  {
    key: 'upperLimitPrice',
    dataIndex: 'upperLimitPrice',
    title: '封顶价格（元）',
    align: 'center',
  },
  {
    key: 'operation',
    dataIndex: 'operation',
    title: '操作',
    align: 'center',
    scopedSlots: {
      customRender: 'operation',
    },
  },
]
const modalAddEditFormRules = {
  id: { initialValue: null },
  geoCode: { rules: [{ required: true, message: '请选择城市区域' }] },
  firstWeight: { rules: [{ required: true, message: '请输入首重' }] },
  firstWeightPrice: { rules: [{ required: true, message: '请输入首重价格' }] },
  continuedWeightPrice: { rules: [{ required: true, message: '请输入续重价格' }] },
  upperLimitPrice: { rules: [{ required: true, message: '请输入封顶价格' }] },
}

export default {
  name: 'TableList',
  components: {
    STable,
  },
  data() {
    return {
      columns: columns,
      dataset: (parameter) => {
        return pickupList(Object.assign(parameter, this.queryParam))
          .then((res) => {
            if (0 == res.code) {
              return res.result
            }
          })
          .catch((e) => {
            console.info(e)
            this.$message.error('数据加载失败，请稍后重试')
          })
      },
      pagination: { pageSizeOptions: ['10', '20', '50'] },
      queryParam: {},
      modalAddEditVisible: false,
      modalAddEditForm: this.$form.createForm(this),
      modalAddEditFormRules: modalAddEditFormRules,
      geoCascaderList: [],
      downloadExcelState: 0,
      downloadExcelDataset: [],
    }
  },
  mounted() {
    const parameter = { showDistrict: 1 }
    geoCascaderList(parameter)
      .then((res) => {
        if (0 == res.code) {
          this.geoCascaderList = res.result
        }
      })
      .catch((e) => {
        console.info(e)
      })
  },
  methods: {
    modalAddEditLoad(record = null) {
      this.modalAddEditForm.resetFields()
      this.modalAddEditVisible = true
      console.log(record)
      if (record) {
        this.$nextTick(() => {
          this.modalAddEditForm.setFieldsValue({
            id: record.id,
            geoCode: [record.provinceCode, record.cityCode, record.districtCode],
            firstWeight: record.firstWeight,
            firstWeightPrice: record.firstWeightPrice,
            continuedWeightPrice: record.continuedWeightPrice,
            upperLimitPrice: record.upperLimitPrice,
          })
        })
      }
    },
    modalAddEditCancel() {
      this.modalAddEditVisible = false
    },
    modalAddEditSubmit(e) {
      e.preventDefault()
      let that = this
      this.modalAddEditForm.validateFields((err, values) => {
        if (!err) {
          values.provinceCode = values.geoCode[0]
          values.cityCode = values.geoCode[1]
          values.districtCode = values.geoCode[2]
          delete values.geoCode
          if (values.id) {
            that.handleUpdate(values)
          } else {
            that.handleInsert(values)
          }
        }
      })
    },
    handleInsert(parameter) {
      pickupInsert(parameter)
        .then((res) => {
          if (0 == res.code) {
            this.$message.success('保存成功')
            this.modalAddEditVisible = false
            this.$refs.pageTable.refresh(true)
          } else {
            this.$message.error('保存失败')
          }
        })
        .catch((e) => {
          console.info(e)
          this.$message.error('保存失败，请稍后重试')
        })
    },
    handleUpdate(parameter) {
      pickupUpdate(parameter)
        .then((res) => {
          if (0 == res.code) {
            this.$message.success('保存成功')
            this.modalAddEditVisible = false
            this.$refs.pageTable.refresh(true)
          } else {
            this.$message.error('保存失败')
          }
        })
        .catch((e) => {
          console.info(e)
          this.$message.error('保存失败，请稍后重试')
        })
    },
    handleRemove(record) {
      pickupRemove(record)
        .then((res) => {
          if (0 == res.code) {
            this.$message.success('删除成功')
            this.$refs.pageTable.refresh(true)
          } else {
            this.$message.error('删除失败')
          }
        })
        .catch((e) => {
          console.info(e)
          this.$message.error('删除失败，请稍后重试')
        })
    },
    downloadExcel() {
      if (this.downloadExcelState > 0) {
        return
      }
      this.downloadExcelState = 1
      this.downloadExcelDataset = [['城市', '区域', '首重KG', '首重价格（元）', '续重价格（元）', '封顶价格（元）']]

      let parameter = { pageNo: 1, pageSize: 100 }
      this.downloadDataset(parameter)
    },
    downloadDataset(parameter) {
      let that = this
      pickupList(Object.assign(parameter, this.queryParam))
        .then((res) => {
          if (0 == res.code) {
            console.log(res.result)
            const result = res.result.result
            for (let i = 0; i < result.length; i++) {
              const dataset = [
                result[i].cityName,
                result[i].districtName,
                result[i].firstWeight,
                result[i].firstWeightPrice,
                result[i].continuedWeightPrice,
                result[i].upperLimitPrice,
              ]
              that.downloadExcelDataset.push(dataset)
            }
            const pageNo = res.result.pageNo
            const pageSize = res.result.pageSize
            const totalCount = res.result.totalCount
            const totalPage = Math.ceil(totalCount / pageSize)
            if (pageNo >= totalPage) {
              console.log(that.downloadExcelDataset)
              let sheet = XLSX.utils.aoa_to_sheet(that.downloadExcelDataset)
              let book = XLSX.utils.book_new()
              XLSX.utils.book_append_sheet(book, sheet, '提货表')
              XLSX.writeFile(book, '提货表.xls')
              that.downloadExcelState = 0
              return
            }
            parameter.pageNo = pageNo + 1
            that.downloadDataset(parameter)
          }
        })
        .catch((e) => {
          console.info(e)
          that.downloadExcelState = 0
          that.$message.error('下载失败，请稍后重试')
        })
    },
  },
}
</script>
<style lang="less" scoped>
.ant-input-number,
.ant-cascader-picker {
  width: 100%;
}
.ant-form{
  /deep/ .ant-form-item-children{
    display: flex;
    .ant-input-number{
      border-radius: 5px 0 0 5px;
    }
    .percentage{
      width: 45px;
      border-radius:0 5px 5px 0;
      border-left: 0;
      padding: 0 0;
    }
  }
}
/deep/ .ant-table-tbody{
  background-color: #fff;
}
/deep/ .ant-form-item-label{
  text-align: left !important;
}
/deep/ .ant-table-pagination {
 > li{
  background-color: #fff !important;
  min-width: 35px !important;
  height: 35px !important;
  line-height: 35px !important;
  border-radius: 4px;
  box-sizing: border-box;
  >div{
    margin: 0 0;
    >div{
      border: none;
    }
   }
  }
  .ant-pagination-options{
   border:1px solid #d9d9d9;
  .ant-select-selection--single {
    height: 32px;
    .ant-select-selection__rendered{
      line-height: 35px;
    }
  }
  }
  .ant-pagination-item-active{
    border-color: #1890ff !important;
  }
}
/deep/ .ant-form-item-control-wrapper{
  width: 70.8%;
}
/deep/  .form-submit {
    margin-bottom: 0;
  .ant-form-item-control-wrapper{
    width: 100% !important;
    text-align: right;
  }
  .ant-form-item-children {
    justify-content: flex-end;
  }
}
</style>